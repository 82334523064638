import type DataObject from 'o365.modules.DataObject.ts';
import type { GroupByExtendedDataItem } from 'o365.modules.DataObject.GroupBy.ts';
import type { ItemModel, DataItemModel} from 'o365.modules.DataObject.Types.ts';

import { BaseGroupBy, GroupByAggregates } from 'o365.modules.DataObject.GroupBy.ts';
import { LayoutGroupByModule } from 'o365.modules.DataObject.Layout.ts';

/**
 * Clientside implementation of GroupBy
 * 
 */
export default class ClientSideGroupBy<T extends ItemModel = ItemModel> extends BaseGroupBy<T> {

    private enabled = false;
    private _originalLoad: any;
    private __groupingFunction?: ClientSideGroupBy<T>['_groupingFunction']

    data: DataItemModel<T>[] = [];

    constructor(options: { dataObject: DataObject<T>, setupOptions: any }) {
        super(options);

        if (options.setupOptions) {
            if (options.setupOptions.initialGroupBy) {
                this.setGroupByForLevel(options.setupOptions.initialGroupBy, 0);
            }
        }

        this._originalLoad = this._dataObject.load.bind(this._dataObject);

        function loadWrapper(pParams?: Object) {
            return this.groupBy.load(pParams);
        }
        this._dataObject.load = loadWrapper;
    }

    postCreateInit() {
        this.enable();
        if (this._dataObject.layoutManager) {
            this._dataObject.layoutManager.registerModule('groupBy', LayoutGroupByModule, {
                baseline: this.groupBy
            });
        }
        this._trackLayout = true;
    };

    enableOverrides() {
        this.enable();
    }

    disableOverrides() {
        this.disable();
    }

    /**
     * Emable client side GroupBy. WIll set maxRecords to -1 and enable client side filtering.
     */
    enable() {
        if (this._groupBy.length === 0) { return; }
        if (!this.enabled) {
            this._dataObject.clientSideFiltering = true;
            this._dataObject.recordSource.maxRecords = -1;
            if (this.__groupingFunction) {
                this._dataObject.dataHandler.groupFormatFunction = this.__groupingFunction
            } else {
                this._dataObject.dataHandler.groupFormatFunction = this._groupingFunction.bind(this);
            }
            this._dataObject.setStoragePointer(this.data);
            this._dataObject.storage.data.forEach(item => this.data.push(item));
            this.enabled = true;
        }
    }

    /**
     * Disable client side GroupBy. Will disable client side filtering.
     */
    disable() {
        if (this.enabled) {
            this._dataObject.clientSideFiltering = false;
            this._dataObject.dataHandler.groupFormatFunction = null;
            this._storage = {};
            this._dataObject.setStoragePointer(undefined);
            this.enabled = false;
        }
    }

    /**
     * Wrapped dataobject load
     */
    load(pParams: any): Promise<any> {
        return this._originalLoad.call(this._dataObject, pParams).then(() => { this._update(); });
    }

    /**
     * Expand group. Is client side
     */
    async loadGroup(group: GroupByExtendedDataItem, _pParams: any, _skipCheck: boolean): Promise<void> {
        if (group == null) {
            group = this._dataObject.current;
        }
        if (!group.o_groupHeaderRow || group.o_expanded) { return; }

        const index = this._getDataIndex(group);

        if (group.o_groupKey == null) {
            console.warn('Could not expand group, no key defined');
            return;
        }

        const dataToAdd = this._storage[group.o_groupKey].details.map((item: GroupByExtendedDataItem) => {
            if (item['key'] != null) {
                return item;
            } else {
                return this._dataObject.storage.addItem(item, this._dataObject.storage.data.length);
            }
        });

        group.o_expanded = true;
        this._storeItemState(group, true);
        if (this._postExpandProcessHandler) {
            await this._postExpandProcessHandler(index, dataToAdd.length);
        }

        //const storageDataToAdd = this._dataObject.storage.setItems(dataToAdd);

        // this._dataObject['_data'].splice(index + 1, 0, ...dataToAdd);
        this.data.splice(index + 1, 0, ...dataToAdd);
        delete this._storage[group.o_groupKey];
        this._update();
    }

    /**
     * Collapse group. Is client side
     */
    async collapseDetails(item: GroupByExtendedDataItem, index?: number): Promise<void> {
        if (!item) {
            item = this._dataObject.current;
        }

        if (!item.o_expanded) { return; }

        if (index == null) {
            index = this._getDataIndex(item);
        }

        if (item.o_groupKey == null) {
            console.warn('Couild not collapse details, row group key is not defined');
            return;
        }

        const currentLevel = item.o_level;
        if (currentLevel == null) {
            console.warn('Could not collapse details, row level not defined');
            return;
        }
        let lastIndex: number | null = null;
        for (let i = index + 1; i < this._dataObject.data.length; i++) {
            const row: GroupByExtendedDataItem = this._dataObject.data[i];
            if (row.o_level != null && row.o_level <= currentLevel) {
                lastIndex = i - 1;
                break;
            }
        }

        if (lastIndex == null) { lastIndex = this._dataObject.data.length - 1; }
        item.o_expanded = false;
        this._storeItemState(item, false);
        if (this._postCollapseProcessHandler) {
            await this._postCollapseProcessHandler(index, lastIndex - index);
        }
        // const splicedData = this._dataObject['_data'].splice(index + 1, lastIndex - index);
        const splicedData = this.data.splice(index + 1, lastIndex - index);
        this._storage[item.o_groupKey] = {
            item: item,
            details: splicedData
        };
        this._update();
    }

    /**
     * Expands all. Is client side
     */
    expandAll(): Promise<any[]> {
        throw new Error('Method not implemented.');
    }
    /**
     * Collapse all. Is client side
     */
    collapseAll(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * Takes in raw data array and groups it
     */
    private _groupingFunction(data: GroupByExtendedDataItem[]) {
        this.data.splice(0, this.data.length);
        this._dataObject.storage.clearItems();
        // data = data.map((item, index) => {
        //     return this._dataObject.storage.addItem(item as T, index);
        // });
        if (this.groupBy.length === 0) { 
            data.forEach((item, index) => {
                this.data.push(this._dataObject.storage.addItem(item as T, index))
            });
            return this.data;
        }

        interface HeaderRow extends GroupByExtendedDataItem {
            [key: string]: any
        };
        type TempStorageItem = {
            item: HeaderRow;
            details: (number | string)[];
            meta: {
                level: number;
                parent: string | null;
            }
        };

        this._storage = {};
        const tempStorage: { [key: string]: TempStorageItem } = {};
        const rootTop: string[] = [];
        const aggregatesStartArr: any[][] = [];
        this.groupBy.forEach(() => {
            aggregatesStartArr.push([]);
        });
        const groupsInStartArr = {};

        const groupCheck = (groupKey: any[], key?: string) => {
            if (key == null) { key = JSON.stringify(groupKey); }
            if (!tempStorage[key]) {
                let parentKey: string | null = null;
                if (groupKey.length === 1) {
                    rootTop.push(key);
                } else {
                    parentKey = JSON.stringify(groupKey.slice(0, groupKey.length - 1))
                }
                const groupItem = groupKey.reduce((res, obj) => {
                    return { ...res, ...obj };
                }, {});
                // Extend field types
                this._dataObject.fields.fields.forEach(field => {
                    if (!groupItem.hasOwnProperty(field.name)) {
                        groupItem[field.name] = undefined;
                    }
                });
                groupItem.o_groupKey
                tempStorage[key] = {
                    item: groupItem,
                    details: [],
                    meta: {
                        level: groupKey.length - 1,
                        parent: parentKey
                    }
                };
            }
        };

        const pushToTempStorage = (groupKey: any[], index: number | string) => {
            const key = JSON.stringify(groupKey);
            groupCheck(groupKey, key);
            if (tempStorage[key].details.includes(index)) { return; }
            tempStorage[key].details.push(index);
            if (!groupsInStartArr[key]) {
                groupsInStartArr[key] = true;
                aggregatesStartArr[groupKey.length - 1].push(key);
            };
            if (groupKey.length > 1) {
                pushToTempStorage(groupKey.slice(0, groupKey.length - 1), key);
            }
        };

        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            const groupKey = this.getGroupByKey(row, this.groupBy.length - 1);
            pushToTempStorage(groupKey, i);
        }

        const formatGroup = (group: TempStorageItem) => {
            const aggregateFunctions: { [key: string]: Function } = this.groupBy.slice(0, group.meta.level + 1).reduce((res, definitions) => {
                definitions.forEach(def => {
                    if (def.groupByAggregate != null) {
                        switch (def.groupByAggregate) {
                            case GroupByAggregates.Custom:
                                res[def.name] = def.groupByAggregateFn;
                                break;
                            case GroupByAggregates.Count:
                                res[def.name] = (data: any[]) => data.reduce((count, item) => {
                                    if (item[def.name] != null) { count++; }
                                    return count;
                                }, 0);
                                break;
                            case GroupByAggregates.Count_Distinct:
                                res[def.name] = (data: any[]) => {
                                    const temp = {};
                                    data.forEach(item => {
                                        if (item[def.name] != null) {
                                            const key = JSON.stringify(item);
                                            if (!temp[key]) { temp[key] = 0 }
                                            temp[key] += 1;
                                        }
                                    });
                                    return Object.keys(temp).length
                                };
                                break;
                            case GroupByAggregates.Avg:
                                res[def.name] = (data: any[]) => {
                                    const sum = data.reduce((sum, item) => {
                                        const value = parseFloat(item[def.name]) || 0;
                                        sum += value;
                                        return sum;
                                    }, 0);
                                    return sum / data.length;
                                };
                                break;
                            case GroupByAggregates.Sum:
                                res[def.name] = (data: any[]) => data.reduce((sum, item) => {
                                    const value = parseFloat(item[def.name]) || 0;
                                    sum += value;
                                    return sum;
                                }, 0);
                                break;
                            case GroupByAggregates.Max:
                                res[def.name] = (data: any[]) => {
                                    let max: number | null = null;
                                    data.forEach(item => {
                                        if (max == null || item[def.name] > max) {
                                            max = item[def.name];
                                        }
                                    });
                                    return max;
                                };
                                break;
                            case GroupByAggregates.Min:
                                res[def.name] = (data: any[]) => {
                                    let min: number | null = null;
                                    data.forEach(item => {
                                        if (min == null || item[def.name] < min) {
                                            min = item[def.name];
                                        }
                                    });
                                    return min;
                                };
                                break;
                            default:
                                break;
                        }
                    }
                });
                return res;
            }, {});

            const getDataItems = (groupItem: TempStorageItem, dataArr: any[], useProxy = true) => {
                if (groupItem == null) { return; }
                groupItem.details.forEach((key) => {
                    if (typeof key === 'number') {
                        if (useProxy) {
                            const row = data[key];
                            const dataObject = this._dataObject;
                            const proxyRow = row;
                            const proxyRow2 = new Proxy(row, {
                                set(target, prop, value) {
                                    if (Object.keys(aggregateFunctions).includes(prop as string)) {
                                        target[prop] = value;

                                        const details = [];
                                        getDataItems(group, details, false);
                                        const result = aggregateFunctions[prop](details);
                                        const dataItemIndex = dataObject.storage.data.findIndex(row => row.o_groupKey === group.item.o_groupKey);
                                        if (dataItemIndex !== -1) {
                                            dataObject.storage.data[dataItemIndex][prop] = result;
                                            // dataObject.data[dataItemIndex][prop] = result;
                                            group.item[prop] = result;
                                            if (dataObject.storage.data[dataItemIndex]?._item) {
                                                dataObject.storage.data[dataItemIndex]._item[prop] = result;
                                                delete dataObject.storage.data[dataItemIndex].oldValues[prop];
                                                dataObject.storage.data[dataItemIndex].updateStates()
                                            }
                                            // dataObject.storage.data[dataItemIndex][prop] = result;
                                            // delete dataObject.data[dataItemIndex].oldValues[prop];
                                            // dataObject.data[dataItemIndex].updateStates()
                                        }

                                        return true;
                                    } else {
                                        return Reflect.set(target, prop, value);
                                    }
                                }
                            });
                            data[key] = proxyRow;
                        }
                        dataArr.push(data[key]);
                    } else {
                        getDataItems(tempStorage[key], dataArr);
                    }
                });
            };

            const detailsData = [];
            getDataItems(group, detailsData);


            Object.entries(aggregateFunctions).forEach(([name, fn]) => {
                const result = fn(detailsData);
                group.item[name] = result;
            });

            group.item.o_level = group.meta.level;
            group.item.o_detailsDirectCount = group.details.length;
            group.item.o_groupHeaderRow = true;
            group.item.o_detailsCount = detailsData.length;
            // const originalItem = group.item;
            // group.item = new Proxy(originalItem, {
            //     get(target, prop, receiver) {
            //         return Reflect.get(target, prop, receiver);
            //     },
            //     set(target, prop, value) {
            //         return Reflect.set(target, prop, value);
            //     }
            // });
        };

        aggregatesStartArr.reverse().forEach((levelGroups) => {
            levelGroups.forEach(key => {
                formatGroup(tempStorage[key]);
            });
        });

        const storedStates = this._getStoredStates();
        Object.entries(storedStates).forEach(([key, expanded]) => {
            if (tempStorage[key]?.item) {
                tempStorage[key].item.o_expanded = expanded;
            }
        });

        const returnData = [];
        const pushToReturn = (dataArray: GroupByExtendedDataItem[], key: string | number, level = 0) => {
            if (typeof key === 'number') {
                const row = data[key];
                row.o_level = level;
                dataArray.push(row);
            } else {
                const group = tempStorage[key];
                group.item.o_groupKey = key;
                level++;
                dataArray.push(group.item);
                if (group.item.o_expanded) {
                    group.details.forEach((detailKey) => {
                        pushToReturn(dataArray, detailKey, level);
                    });
                } else {
                    this._storage[key] = {
                        item: group.item,
                        details: []
                    };
                    group.details.forEach((detailKey) => {
                        pushToReturn(this._storage[key].details, detailKey, level);
                    });
                }
            }
        };
        rootTop.forEach(key => {
            pushToReturn(returnData, key);
        });

        returnData.forEach(item => {
            if (item.state == null) {
                item = this._dataObject.storage.addItem(item, this._dataObject.storage.data.length);
            }
            this.data.push(item);
        });
        this._update();
        return this.data;
    }

    /**
     * Client side groupby does not use last detail format function
     */
    setLastDetailFormatFunction() { return; }

    /**
     * Rebinds the group format function with the current object 'this'
     */
    bindReactiveFormatFunction() {
        // this.__groupingFunction = this._groupingFunction.bind(this);
    }
}